export const Color = {
  CSSVarMap: {
    Background: '--color-background',
    Border: '--color-border',
    Caution: '--color-caution',
    CautionDark: '--color-caution-dark',
    CautionFaded: '--color-caution-faded',
    CautionLight: '--color-caution-light',
    Critical: '--color-critical',
    CriticalDark: '--color-critical-dark',
    CriticalFaded: '--color-critical-faded',
    CriticalLight: '--color-critical-light',
    Info: '--color-info',
    InfoDark: '--color-info-dark',
    InfoFaded: '--color-info-faded',
    InfoLight: '--color-info-light',
    Main: '--color-main',
    MainDark: '--color-main-dark',
    MainFaded: '--color-main-faded',
    MainLight: '--color-main-light',
    Neutral: '--color-neutral',
    NeutralDark: '--color-neutral-dark',
    NeutralFaded: '--color-neutral-faded',
    NeutralLight: '--color-neutral-light',
    Overlay: '--color-overlay',
    Positive: '--color-positive',
    PositiveDark: '--color-positive-dark',
    PositiveFaded: '--color-positive-faded',
    PositiveLight: '--color-positive-light',
    Surface: '--color-surface',
    SurfaceStrong: '--color-surface-strong',
    SurfaceSubtle: '--color-surface-subtle',
    Text: '--color-text',
    TextSemantic: '--color-text-semantic',
    TextStrong: '--color-text-strong',
    TextSubtle: '--color-text-subtle',
    ScrollbarTrack: '--color-scrollbar-track',
    ScrollbarThumb: '--color-scrollbar-thumb',
    ScrollbarThumbHighlight: '--color-scrollbar-thumb-highlight',
    InputBackground: '--color-input-background',
  },

  Light: {
    Background: '#FFFFFF',
    Border: '#D4DADD',
    Caution: '#F9B21F',
    CautionDark: '#BD7F00',
    CautionFaded: '#FAB11E66',
    CautionLight: '#FFC857',
    Critical: '#E5401A',
    CriticalDark: '#B83314',
    CriticalFaded: '#E5401A66',
    CriticalLight: '#FF8366',
    Info: '#26D9D9',
    InfoDark: '#1C9C9C',
    InfoFaded: '#26D9D966',
    InfoLight: '#7DE8E8',
    Main: '#000e2a',
    MainDark: '#3373CC',
    MainFaded: '#4D8CE566',
    MainLight: '#99C2FF',
    Neutral: '#8FA0A3',
    NeutralDark: '#819498',
    NeutralFaded: '#8fa0a366',
    NeutralLight: '#C6CFD2',
    Overlay: '#0C1114BF',
    Positive: '#2EB87C',
    PositiveDark: '#196645',
    PositiveFaded: '#2EB87C66',
    PositiveLight: '#70DBAD',
    Surface: '#e8ebed',
    SurfaceStrong: '#dee1e3',
    SurfaceSubtle: '#f0f2f2',
    Text: '#00111ABF',
    TextSemantic: '#FFFFFF',
    TextStrong: '#00111AF2',
    TextSubtle: '#00111A73',
    ScrollbarTrack: '#d4d9d9',
    ScrollbarThumb: '#afb3b3',
    ScrollbarThumbHighlight: '#bbbfbf',
    InputBackground: '#ccd5d7',
  },
}
