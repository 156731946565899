import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { OVERVIEW_ROUTES } from './constants'
import { IndexScreen } from '@src/screens/index'
import { PrivacyScreen } from '@src/screens/privacy'
import { Header } from '@src/screens/components/Header'
import { Footer } from '@src/screens/components/Footer'
import { TermsScreen } from '@src/screens/terms'
import { RegistrationSignup } from '@src/screens/RegistrationSignup'
import { RegisterSites } from '@src/screens/RegisterSites'
import { SitesSurveyCompletion } from '@src/screens/SitesSurveyCompletion'

const DashboardRoutes = (
  <Route
    path={OVERVIEW_ROUTES.__ROOT__}
    render={() => (
      <>
        <Header />
        <div className="pt-24">
          <Switch>
            <Route component={PrivacyScreen} path={OVERVIEW_ROUTES.PRIVACY} />
            <Route component={TermsScreen} path={OVERVIEW_ROUTES.TERMS} />
            <Route
              component={RegistrationSignup}
              path={OVERVIEW_ROUTES.REGISTER}
            />
            <Route
              component={RegisterSites}
              path={OVERVIEW_ROUTES.REGISTER_SITES}
            />
            <Route
              component={SitesSurveyCompletion}
              path={OVERVIEW_ROUTES.SITES_SURVEY_COMPLETION}
            />
            <Route component={IndexScreen} />
          </Switch>
        </div>
        <Footer />
      </>
    )}
  />
)

export { DashboardRoutes }
