import React, { useState } from 'react'
import { Alerts } from '@src/utils/Alerts'
import { Loading } from '@therms/atalaya'
import { SitesSurveyModal } from '@src/screens/components/SitesSurveyModal'
import { RegistrationSignupForm } from '@src/screens/RegistrationSignupForm'
import { engineRoomApi } from '@src/services/api'

function RegistrationSignup() {
  const [loading, setLoading] = useState(false)
  const [showSitesSurveyModal, setShowSitesSurveyModal] = useState<string>()

  const handleSubmit = async (values) => {
    setLoading(true)

    try {
      const response = await engineRoomApi.registration.signUp({
        email: values.email,
        userName: values.userName,
        phone: values.phone,
        organizationName: values.organizationName,
        brands: values.brands,
        numberOfSites: values.numberOfSites ? +values.numberOfSites : 0,
      })

      Alerts.success(`Registration submitted`)

      setShowSitesSurveyModal(response.data.id)
    } catch (err: any) {
      Alerts.error('There was a problem submitting registration', err.message)

      console.log('save() err', err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full px-2">
      {loading && <Loading overlay size="xxl" />}

      <div className="flex items-center justify-center">
        <div className="max-w-[500px] flex-grow">
          <div className="text-center text-lg">Register Your Organization</div>
          <RegistrationSignupForm loading={loading} onSubmit={handleSubmit} />
        </div>
      </div>

      {!!showSitesSurveyModal && (
        <SitesSurveyModal
          onClose={setShowSitesSurveyModal}
          registrationId={showSitesSurveyModal}
        />
      )}
    </div>
  )
}

export { RegistrationSignup }
