// @ts-nocheck
/* eslint-disable import/no-import-module-exports */
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from '@src/App'

ReactDOM.render(<App />, document.querySelector('#root'))

// dev env HMR
if (module?.hot) {
  module.hot.accept()
}
