import React from 'react'
import { Button, Modal, Stack } from '@therms/atalaya'
import { OVERVIEW_ROUTES } from '@src/screens/constants'
import { generatePath, useHistory } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa6'

interface SitesSurveyModalProps {
  onClose: (value: string) => void
  registrationId: string
}

export function SitesSurveyModal({
  onClose,
  registrationId,
}: SitesSurveyModalProps) {
  const history = useHistory()

  return (
    <Modal closeHandler={() => onClose('')} header="Registration Successful">
      <Stack space="lg">
        <div className="text-center text-md font-bold">
          Would you like to provide information about your Sites now or at a
          later time?
        </div>

        <div className="grid grid-cols-5">
          <div className="col-span-1 col-start-2 grid">
            <Button
              className="text-md font-bold"
              onClick={() => history.push(OVERVIEW_ROUTES.__ROOT__)}
              variant="main"
              subtle
            >
              Later
            </Button>
          </div>

          <div className="col-span-1 col-start-4 grid">
            <Button
              className="text-md font-bold"
              onClick={() =>
                history.push(
                  generatePath(OVERVIEW_ROUTES.REGISTER_SITES, {
                    registrationId,
                  }),
                )
              }
              variant="main"
            >
              <span className="pr-sm">Continue</span>
              <FaArrowRight />
            </Button>
          </div>
        </div>
      </Stack>
    </Modal>
  )
}
