import React from 'react'
import { Button, ButtonConfirm, Card, Inline, Stack } from '@therms/atalaya'
import { FormikText } from '@src/screens/components/FormikFields/FormikText'
import { FormikSelectBrand } from '@src/modules/Brands/shared/FormikSelectBrand'
import { AddressSuggestions } from '@src/screens/components/AddressSuggestions/AddressSuggestions'
import { Field, useFormikContext, FieldArrayRenderProps } from 'formik'
import { MOBILE_DEVICE_TYPES, WALLBOARD_TV_SIZES } from '@src/screens/constants'
import { engineRoomApi } from '@src/services/api'
import { PartialTRegistrationSite } from '@src/services/generated-api'

interface SitesSurveySitesListCardProps {
  arrayHelpers: FieldArrayRenderProps
  index: number
  siteSurveyInitialValues?: { sites: PartialTRegistrationSite[] }
}

function SitesSurveySitesListCard({
  arrayHelpers,
  index,
  siteSurveyInitialValues,
}: SitesSurveySitesListCardProps) {
  const form = useFormikContext<{ sites: PartialTRegistrationSite[] }>()

  return (
    <Card className="max-w-[800px]" key={index}>
      <Stack>
        <div className="grid w-full grid-cols-1 gap-1 pb-xxs md:grid-cols-2">
          <FormikText
            label="Site Name"
            name={`sites.${index}.siteName`}
            placeholder="Site Name"
            search
          />

          <FormikSelectBrand
            label="Brands"
            name={`sites.${index}.brands`}
            value={form.values.sites[index]?.brands}
            combineSelected
          />
        </div>

        <div className="col-span-3 grid w-full pb-xxs">
          <AddressSuggestions
            label="Address"
            placeholder="Enter address..."
            onChangeValue={(addressInfo) => {
              form.setFieldValue(`sites.${index}.address`, addressInfo)
            }}
            value={
              siteSurveyInitialValues?.sites[index]
                ? siteSurveyInitialValues?.sites[index].address.address
                : undefined
            }
          />
        </div>

        <div className="grid w-full grid-cols-1 gap-1 pb-xxs md:grid-cols-3">
          <FormikText
            label="Manager Name"
            name={`sites.${index}.managerName`}
            placeholder="Manager Name"
            search
            subText="The site manager name that will have access to manage the site's information & tasks"
          />

          <FormikText
            label="Manager Phone"
            name={`sites.${index}.managerPhone`}
            placeholder="Manager Phone"
            search
          />

          <FormikText
            label="Manager Email"
            name={`sites.${index}.managerEmail`}
            placeholder="Manager Email"
            search
          />
        </div>

        <div className="grid grid-cols-1 gap-1 pt-2 md:grid-cols-2">
          <Stack space="xs">
            <Inline alignX="start" alignY="center" space="xs">
              <Field name={`sites.${index}.hasWallboard`} type="checkbox" />
              <span>Site has a TV able to use Wallboard App</span>
            </Inline>

            {!form.values.sites[index]?.hasWallboard && (
              <Stack>
                <span className="underline">TV wallboard options</span>

                <Inline alignX="start" alignY="center" space="xs">
                  <Field
                    name={`sites.${index}.tvSize`}
                    active={
                      `sites.${index}.tvSize` === WALLBOARD_TV_SIZES['40']
                    }
                    checked={
                      form.values.sites[index]?.tvSize ===
                      WALLBOARD_TV_SIZES['40']
                    }
                    type="radio"
                    onChange={() =>
                      form.setFieldValue(
                        `sites.${index}.tvSize`,
                        WALLBOARD_TV_SIZES['40'],
                      )
                    }
                  />
                  <span>{`${WALLBOARD_TV_SIZES['40']}"`}</span>
                </Inline>

                <Inline alignX="start" alignY="center" space="xs">
                  <Field
                    name={`sites.${index}.tvSize`}
                    active={
                      `sites.${index}.tvSize` === WALLBOARD_TV_SIZES['43']
                    }
                    checked={
                      form.values.sites[index]?.tvSize ===
                      WALLBOARD_TV_SIZES['43']
                    }
                    onChange={() =>
                      form.setFieldValue(
                        `sites.${index}.tvSize`,
                        WALLBOARD_TV_SIZES['43'],
                      )
                    }
                    type="radio"
                  />
                  <span>{`${WALLBOARD_TV_SIZES['43']}"`}</span>
                </Inline>
              </Stack>
            )}
          </Stack>

          <Stack space="sm">
            <Inline alignX="start" alignY="center" space="xs">
              <Field name={`sites.${index}.hasInternet`} type="checkbox" />
              <span>Site has internet</span>
            </Inline>

            <Stack>
              <span className="underline">Mobile device used by employees</span>

              <Inline alignX="start" alignY="center" space="xs">
                <Field
                  name={`sites.${index}.mobileDevice`}
                  checked={
                    form.values.sites[index]?.mobileDevice ===
                    MOBILE_DEVICE_TYPES.EMPLOYEE
                  }
                  type="radio"
                  onChange={() =>
                    form.setFieldValue(
                      `sites.${index}.mobileDevice`,
                      MOBILE_DEVICE_TYPES.EMPLOYEE,
                    )
                  }
                />
                <span>Employees use own devices</span>
              </Inline>

              <Inline alignX="start" alignY="center" space="xs">
                <Field
                  name={`sites.${index}.mobileDevice`}
                  checked={
                    form.values.sites[index]?.mobileDevice ===
                    MOBILE_DEVICE_TYPES.STORE
                  }
                  type="radio"
                  onChange={() =>
                    form.setFieldValue(
                      `sites.${index}.mobileDevice`,
                      MOBILE_DEVICE_TYPES.STORE,
                    )
                  }
                />
                <span>Employers supply devices</span>
              </Inline>
            </Stack>
          </Stack>
        </div>
      </Stack>

      <div className="flex w-full justify-end">
        <ButtonConfirm
          confirmationVariant="critical"
          confirmationMessage="Confirm site removal"
          subtle
          variant="main"
          onClick={() => {
            engineRoomApi.registration.deleteRegistrationSite({
              id: form.values.sites[index].id,
            })
            arrayHelpers.remove(index)
            siteSurveyInitialValues?.sites.splice(index, 1)
          }}
        >
          - Remove site
        </ButtonConfirm>
      </div>
    </Card>
  )
}

export { SitesSurveySitesListCard }
