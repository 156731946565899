import { useEffect, useState } from 'react'
import throttle from 'lodash-es/throttle'

export function useWindowSize(): {
    height: number
    width: number
} {
    const [windowSize, setWindowSize] = useState<{
        height: number
        width: number
    }>({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        const handleResize = throttle(
            () => {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                })
            },
            150,
            { trailing: true, leading: true },
        )

        window?.addEventListener('resize', handleResize as any)

        handleResize()

        return () => window?.removeEventListener('resize', handleResize as any)
    }, [])

    return windowSize
}
