import React from 'react'
import Vimeo from '@u-wave/react-vimeo'
import iphoneMockImg from '../assets/iphone-mock-app-portait.png'
import seven11Img from '../assets/7_Eleven_Horizontal_2022.png'
import seventy6Img from '../assets/76.png'
import phillips66Img from '../assets/phillips_66.png'
import conocoImg from '../assets/conoco.png'
import { useWindowSize } from '@src/hooks/useWindowSize'
import { Inline } from '@therms/atalaya'

export function IndexScreen() {
  const { width } = useWindowSize()

  return (
    <section className="body-font text-gray-600">
      <div className="mx-auto flex max-w-7xl flex-col items-center px-5 pb-24 md:flex-row">
        <div className="mb-40 flex flex-col items-center pt-6 text-center md:ml-24 md:w-1/2 md:items-start md:text-left lg:flex-grow">
          <h1 className="Avenir xl:w-2/2 mb-5 items-center text-5xl text-gray-900 sm:text-6xl">
            C-Store Task Tracking Software
          </h1>
          <p className="mb-4 text-md capitalize text-gray-600 xl:w-3/4">
            Know it's done & done right.
          </p>
          {/* <div className="flex justify-center"> */}
          {/*  <a */}
          {/*    className="mt-2 inline-flex transform items-center rounded-lg border bg-transparent bg-gray-900 px-5 py-3 font-medium text-white transition duration-500 ease-in-out" */}
          {/*    href="https://github.com/r1/nine4-2/" */}
          {/*  > */}
          {/*    <span className="justify-center">Find out more</span> */}
          {/*  </a> */}
          {/* </div> */}
          <Inline className="" width="full">
            <a href="https://apps.apple.com/us/app/quicktask-daily/id1629961190">
              <img
                src="https://woosports.com/wp-content/uploads/2020/07/toppng.com-android-app-store-app-store-and-android-icons-2145x1493-copy.png"
                alt="ios app store"
                width={200}
              />
            </a>

            <div className="mx-1" />

            <a href="https://play.google.com/store/apps/details?id=com.quicktaskdaily">
              <img
                src="https://woosports.com/wp-content/uploads/2020/07/toppng.com-android-app-store-app-store-and-android-icons-2145x1493-1.png"
                alt="android play store"
                width={200}
              />
            </a>
          </Inline>
        </div>
        <div className="mb-0 mr-48 sm:mb-28 sm:mr-0 md:pl-10 lg:mb-0 xl:mr-44">
          <img
            className="ml-24 w-80 md:ml-1"
            alt="iPhone-12"
            src={iphoneMockImg}
          />
        </div>
      </div>

      {/* <section className="mx-auto"> */}
      {/*   <div className="container mx-auto px-5 lg:px-24 "> */}
      {/*     <div className="mb-4 flex w-full flex-col text-left lg:text-center"> */}
      {/*       <h1 className="Avenir mb-8 text-2xl font-semibold text-black"> */}
      {/*         Trusted by top-tier companies */}
      {/*       </h1> */}
      {/*     </div> */}
      {/*     <div className="mb-16 grid grid-cols-2 gap-16 text-center lg:grid-cols-4"> */}
      {/*       <div className="flex items-center justify-center"> */}
      {/*         <img */}
      {/*             src={seventy6Img} */}
      {/*             alt="76" */}
      {/*             className="greyC block h-16 object-contain" */}
      {/*         /> */}
      {/*       </div> */}
      {/*       <div className="flex items-center justify-center"> */}
      {/*         <img */}
      {/*             src={conocoImg} */}
      {/*             alt="Conoco" */}
      {/*             className="greyC block h-16 object-contain" */}
      {/*         /> */}
      {/*       </div> */}
      {/*       <div className="flex items-center justify-center"> */}
      {/*         <img */}
      {/*             src={phillips66Img} */}
      {/*             alt="Phillips 66" */}
      {/*             className="greyC block h-16 object-contain" */}
      {/*         /> */}
      {/*       </div> */}
      {/*       <div className="flex items-center justify-center"> */}
      {/*         <img */}
      {/*             src={seven11Img} */}
      {/*             alt="7-Eleven" */}
      {/*             className="greyC block h-16 object-contain" */}
      {/*         /> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </section> */}

      <div className="grr mx-auto max-w-7xl pt-20 text-center">
        <h1 className="Avenir mb-8 text-6xl font-semibold text-gray-900">
          Easy oversight, less effort.
        </h1>
        <h1 className="Avenir mb-8 flex justify-center text-center text-lg font-semibold text-gray-600">
          <div className="max-w-lg">
            "It's like adding an Assistant Manager for pennies a day." <br />
            -Operator Store #4675
          </div>
        </h1>
        {/* <div className="container mx-auto flex flex-col items-center justify-center rounded-lg "> */}
        {/* <img */}
        {/*   className="g327 mb-10 w-3/4 rounded-lg border object-cover object-center shadow-md" */}
        {/*   alt="Placeholder Image" */}
        {/*   src="./images/placeholder.png" */}
        {/* /> */}
        {/* </div> */}
      </div>

      <div className="mb-12 flex justify-center">
        <Vimeo
          video="https://vimeo.com/871984949/c95d1b726a"
          width={width * 0.8}
        />
      </div>

      {/* <section className="relative"> */}
      {/* <div className="mx-auto max-w-6xl px-4 text-center sm:px-6"> */}
      {/*   <div className="py-24 md:py-36"> */}
      {/*     <h1 className="Avenir mb-5 text-6xl font-semibold text-gray-900"> */}
      {/*       Subscribe to our newsletter */}
      {/*     </h1> */}
      {/*     <h1 className="mb-9 text-2xl font-semibold text-gray-600"> */}
      {/*       Enter your email address and get our newsletters straight away. */}
      {/*     </h1> */}
      {/*     <input */}
      {/*       placeholder="jack@example.com" */}
      {/*       name="email" */}
      {/*       type="email" */}
      {/*       autoComplete="email" */}
      {/*       className="mt-2 w-1/4 rounded-md border border-gray-600 py-3 pr-2 pl-2 font-semibold text-gray-800 hover:border-gray-900" */}
      {/*     />{' '} */}
      {/*     <a */}
      {/*       className="mt-2 ml-2 inline-flex transform items-center rounded-lg border bg-transparent bg-gray-900 px-14 py-3 font-medium text-white transition duration-500 ease-in-out" */}
      {/*       href="/" */}
      {/*     > */}
      {/*       <span className="justify-center">Subscribe</span> */}
      {/*     </a> */}
      {/*   </div> */}
      {/* </div> */}
      {/* </section> */}
    </section>
  )
}
