import React, { useEffect, useState } from 'react'
import { Formik, Form, FieldArray } from 'formik'
import { Button } from '@therms/atalaya'
import { Alerts } from '@src/utils/Alerts'
import { OVERVIEW_ROUTES } from '@src/screens/constants'
import { useParams } from 'react-router-dom'
import { history } from '@src/AppRouter'
import { SitesSurveySitesListCard } from '@src/screens/components/SitesSurvey/SitesSurveySitesListCard'
import { engineRoomApi } from '@src/services/api'
import {
  PartialTRegistrationSite,
  TRegistrationSite,
} from '@src/services/generated-api'

interface FormikSitesListProps {
  registrationId: string
}

export function SitesSurveySitesList({ registrationId }: FormikSitesListProps) {
  const params = useParams<{ registrationId: string }>()
  const initialValues: { sites: PartialTRegistrationSite[] } = {
    sites: [
      {
        siteName: '',
        brands: [],
        address: {
          address: '',
          coords: undefined,
          googlePlaceId: '',
        },
        hasWallboard: true,
        hasInternet: false,
        id: undefined,
        managerName: '',
        managerPhone: '',
        managerEmail: '',
        registrationId,
        tvSize: '',
        mobileDevice: '',
      },
    ],
  }
  const [siteSurveyInitialValues, setSiteSurveyInitialValues] = useState<{
    sites: TRegistrationSite[]
  }>()

  const getSiteSurveyDetails = async () => {
    try {
      const response = await engineRoomApi.registration.getRegistrationSites(
        params.registrationId,
        { registrationId: params.registrationId },
      )

      if (response.data.length) {
        setSiteSurveyInitialValues({ sites: response.data })
      }
    } catch (err: any) {
      Alerts.error(
        'There was a problem getting site survey details',
        err.message,
      )
      console.log('getSiteSurveyDetails err', err)
    }
    return null
  }

  useEffect(() => {
    getSiteSurveyDetails()
  }, [])

  const handleSubmit = async (formValues: {
    sites: PartialTRegistrationSite[]
  }) => {
    try {
      await engineRoomApi.registration.saveRegistrationSites(formValues.sites)

      Alerts.success('Site details submitted')

      history.push(OVERVIEW_ROUTES.SITES_SURVEY_COMPLETION)
    } catch (err: any) {
      Alerts.error('There was a problem submitting Site details', err.message)

      console.log('save() err', err)
    }
  }

  return (
    <div className="w-full space-y-base px-2">
      <div className="text-lg font-bold">Registration Sites Details</div>

      <div className="text-sm italic">
        Provide details about your sites to help us prepare for your service.
      </div>

      <div className="flex w-full flex-col items-center justify-center space-y-base px-2">
        <Formik
          enableReinitialize
          initialValues={siteSurveyInitialValues || initialValues}
          onSubmit={handleSubmit}
        >
          {({ submitForm, values }) => (
            <Form>
              <FieldArray
                name="sites"
                render={(arrayHelpers) => (
                  <div>
                    {arrayHelpers &&
                    arrayHelpers.form.values.sites?.length > 0 ? (
                      <div className="space-y-base">
                        {values.sites.map((site, index) => (
                          <SitesSurveySitesListCard
                            arrayHelpers={arrayHelpers}
                            index={index}
                            siteSurveyInitialValues={siteSurveyInitialValues}
                          />
                        ))}

                        <div className="flex w-full justify-center pt-base">
                          <Button
                            variant="positive"
                            onClick={() =>
                              arrayHelpers.push(initialValues.sites[0])
                            }
                          >
                            + Add another site
                          </Button>
                        </div>

                        <div className="flex w-full justify-center pt-base">
                          <Button
                            onClick={submitForm}
                            variant="main"
                            size="lg"
                            fullWidth
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex w-full justify-center pt-base">
                        <Button
                          variant="main"
                          onClick={() => arrayHelpers.push(initialValues[0])}
                        >
                          + Add a site
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
