/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ERegistrationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface TRegistration {
  userName: string
  timestamp: string
  statusDate: string
  status: ERegistrationStatus
  phone: string
  organizationName: string
  /** @format double */
  numberOfSites: number
  id: string
  email: string
  brands: string[]
}

export interface TRegistrationsListFilter {
  statusDate?: string
  status?: ERegistrationStatus
  /** @format double */
  skip?: number
  searchText?: string
  /** @format double */
  limit?: number
  registrationId?: string
  brands?: string[]
}

export interface TRegistrationSite {
  mobileDevice?: string
  tvSize?: string
  registrationId: string
  hasWallboard?: boolean
  hasInternet?: boolean
  managerEmail?: string
  managerPhone?: string
  managerName?: string
  id: string
  address?: {
    coords?: {
      /** @format double */
      lng?: number
      /** @format double */
      lat?: number
    }
    googlePlaceId?: string
    address?: string
  }
  brands?: string[]
  siteName?: string
}

/** Make all properties in T optional */
export interface PartialTRegistrationSite {
  siteName?: string
  brands?: string[]
  address?: {
    coords?: {
      /** @format double */
      lng?: number
      /** @format double */
      lat?: number
    }
    googlePlaceId?: string
    address?: string
  }
  id?: string
  managerName?: string
  managerPhone?: string
  managerEmail?: string
  hasInternet?: boolean
  hasWallboard?: boolean
  registrationId?: string
  tvSize?: string
  mobileDevice?: string
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title quicktask-engineroom
 * @version 1.0.0
 * @license ISC
 * @baseUrl /
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  registration = {
    /**
     * No description
     *
     * @name GetRegistrationDetails
     * @request GET:/registration/get-registration-details/{registrationId}
     */
    getRegistrationDetails: (registrationId: string, params: RequestParams = {}) =>
      this.request<TRegistration, any>({
        path: `/registration/get-registration-details/${registrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetRegistrationsList
     * @request POST:/registration/get-registrations-list
     */
    getRegistrationsList: (data: TRegistrationsListFilter, params: RequestParams = {}) =>
      this.request<
        {
          /** @format double */
          total: number
          registrations: TRegistration[]
        },
        any
      >({
        path: `/registration/get-registrations-list`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SignUp
     * @request POST:/registration/sign-up
     */
    signUp: (
      data: {
        userName: string
        phone: string
        organizationName: string
        /** @format double */
        numberOfSites: number
        email: string
        brands: string[]
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string
        },
        any
      >({
        path: `/registration/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetRegistrationSites
     * @request POST:/registration/get-registration-site-surveys/{registrationId}
     */
    getRegistrationSites: (
      registrationId: string,
      data: {
        searchText?: string
        registrationId?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<TRegistrationSite[], any>({
        path: `/registration/get-registration-site-surveys/${registrationId}`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteRegistrationSite
     * @request POST:/registration/delete-site
     */
    deleteRegistrationSite: (
      data: {
        id: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/registration/delete-site`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SaveRegistrationSites
     * @request POST:/registration/save-registration-sites
     */
    saveRegistrationSites: (data: PartialTRegistrationSite[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/registration/save-registration-sites`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ChangeRegistrationStatus
     * @request POST:/registration/change-registration-status
     */
    changeRegistrationStatus: (
      data: {
        status: ERegistrationStatus
        registrationId: string
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/registration/change-registration-status`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  geo = {
    /**
     * No description
     *
     * @name AddressAutocomplete
     * @request POST:/geo/address-autocomplete
     */
    addressAutocomplete: (
      data: {
        text: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          results: {
            place_id?: string
            geometry?: {
              location: {
                lng: string
                lat: string
              }
            }
            formatted_address?: string
          }[]
        },
        any
      >({
        path: `/geo/address-autocomplete`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
