import React from 'react'
import { Select, SelectProps } from '@therms/atalaya'
import { useBrands } from '../hooks/useBrands'

export interface SelectBrandProps extends Omit<SelectProps, 'options'> {
  onChangeValue: (value: string[]) => void
  value: string[]
}

function SelectBrand({
  onChangeValue,
  value,
  ...atalayaSelectProps
}: SelectBrandProps) {
  const { brands, loading } = useBrands()

  return (
    <Select

      {...atalayaSelectProps}
      isLoading={loading}
      multi
      placeholder="Brands..."
      onChangeValue={onChangeValue}
      options={brands || []}
      optionLabelExtractor={(brand) => brand.name}
      optionValueExtractor={(brand) => brand.id}
      value={value}
    />
  )
}

export { SelectBrand }
