import React from 'react'
import logo from '@src/assets/logo.png'
import { Image } from '@therms/atalaya'
import { OVERVIEW_ROUTES } from '@src/screens/constants'

export function Header() {
  const [navbarOpen, setNavbarOpen] = React.useState(false)

  return (
    <div className="clearNav fixed top-0 z-30 w-full transition duration-300 ease-in-out md:bg-opacity-90">
      <div className="mx-auto flex max-w-6xl flex-col px-4 md:flex-row md:items-center md:justify-between md:px-6 lg:px-8">
        <div className="flex flex-row items-center justify-between p-4">
          <a
            href="/"
            className="focus:shadow-outline rounded-lg text-lg font-semibold tracking-widest focus:outline-none"
          >
            <h1 className="Avenir md:text-4x1 text-4xl tracking-tighter text-gray-900 lg:text-3xl">
              <div className="flex flex-row items-center space-x-1">
                <Image src={logo} height={50} />
                <span className="">QuickTask</span>
              </div>
            </h1>
          </a>
          <button
            className="cursor-pointer px-3 py-1 leading-none text-white outline-none focus:outline-none md:hidden "
            type="button"
            aria-label="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#191919"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
          </button>
        </div>
        <div
          className={`flex-grow md:flex items-center${
            navbarOpen ? ' flex' : ' hidden'
          }`}
        >
          <nav className="flex-grow flex-col ">
            <ul className="flex flex-grow flex-wrap items-center justify-end">
              <li>
                <a
                  href="https://admin.quicktask.us"
                  className="flex items-center px-5 py-3 font-medium text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
                >
                  Login
                </a>
              </li>
              <li>
                <a
                  className="mt-2 inline-flex transform items-center rounded-lg bg-main px-4 py-2 text-white transition duration-500 ease-in-out md:ml-4 md:mt-0"
                  href={OVERVIEW_ROUTES.REGISTER}
                >
                  <span className="justify-center">Register</span>
                  <svg
                    className="ml-2 -mr-1 flex h-3 w-3 fill-current text-gray-400"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                      fillRule="nonzero"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
