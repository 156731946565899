import { useState } from 'react'
import { useMemoDebounced } from './useMemoDebounced'

export function useStateDebounced(initState, delay = 500) {
  const [state, setState] = useState(initState)

  const debouncedValue = useMemoDebounced(() => state, [state], delay)

  return [state, debouncedValue, setState]
}
