import { atom, useAtom } from 'jotai'
// import { ExecApiRequests } from '../../../services/api-requests'
import { useEffect } from 'react'
import { BRANDS } from '@ecc/domain-brands'

const brandsAtom = atom(new Map<string, { id: string; name: string }>())
const brandsFetchAtom = atom<{
  hasFetched: boolean
  isFetching: boolean
}>({
  hasFetched: false,
  isFetching: false,
})

function useBrands() {
  const [brands, setBrands] = useAtom(brandsAtom)
  const [brandsFetch, setBrandsFetch] = useAtom(brandsFetchAtom)

  useEffect(() => {
    const fetchBrands = async () => {
      if (!brandsFetch.hasFetched && !brandsFetch.isFetching) {
        setBrandsFetch({
          isFetching: true,
          hasFetched: false,
        })

        // const { data: brandsData } = await ExecApiRequests.getBrands()
        // const brandsData = BRANDS

        const brandsMap = BRANDS.reduce((map, brand) => {
          map.set(brand.id, brand)
          return map
        }, new Map())

        setBrands(brandsMap)

        setBrandsFetch({
          hasFetched: true,
          isFetching: false,
        })
      }
    }

    fetchBrands()
  }, [brandsFetch, setBrands, setBrandsFetch])

  return { brands: [...brands.values()], loading: brandsFetch.isFetching }
}

function useBrand({ brandId }) {
  const { brands } = useBrands()
  return brands.find(({ id }) => brandId === id)
}

export { useBrands, useBrand }
