import React, { StrictMode } from 'react'
import { AppRouter } from './AppRouter'
import { AtalayaWrapper, useCSS } from '@therms/atalaya'
import './index.css'
import { Color } from '@src/Color'

export function App() {
  useCSS({
    '--border-radius-base': '.365rem',
  })

  return (
    <StrictMode>
      <AtalayaWrapper
        theme={{
          dark: Color.Light,
          light: Color.Light,
        }}
      >
        <AppRouter />
      </AtalayaWrapper>
    </StrictMode>
  )
}
