import React from 'react'
import { SitesSurveySitesList } from '@src/screens/components/SitesSurvey/SitesSurveySitesList'
import { useParams } from 'react-router-dom'

function RegisterSites() {
  const { registrationId } = useParams<{ registrationId: string }>()

  return (
    <div className="flex justify-center">
      <SitesSurveySitesList registrationId={registrationId} />
    </div>
  )
}

export { RegisterSites }
