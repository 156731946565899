import React from 'react'
import { Router, Redirect, Switch } from 'react-router-dom'
// Note: this "history" package must match the react-router-dom version compatibility
import { createBrowserHistory } from 'history'
import { DashboardRoutes } from '@src/screens/Routes'

export const history = createBrowserHistory()

function AppRouter() {
  return (
    <Router history={history}>
      <Switch>
        {DashboardRoutes}

        <Redirect to="/" />
      </Switch>
    </Router>
  )
}

export { AppRouter }
