import React from 'react'
import { Number } from '@therms/atalaya'
import { Field, FieldAttributes, useFormikContext } from 'formik'

function FormikNumber(props: FieldAttributes<Parameters<typeof Number>[0]>) {
  const form = useFormikContext()

  return (
    <Field name={props.name}>
      {({ field, meta }) => (
        <Number
          {...props}
          error={(meta.touched || !!form.submitCount) && meta.error}
          valid={
            (meta.touched || !!form.submitCount) && meta.value && !meta.error
          }
          onChangeValue={(value) =>
            props.name && form.setFieldValue(props.name, value)
          }
          {...field}
          // this component relies on `onChangeValue`
          onChange={null}
        />
      )}
    </Field>
  )
}

export { FormikNumber }
