export const OVERVIEW_ROUTES = {
  __ROOT__: '/',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  REGISTER: '/register',
  REGISTER_SITES: '/register-sites/:registrationId',
  SITES_SURVEY_COMPLETION: '/sites-survey-completion',
}

export const WALLBOARD_TV_SIZES = {
  40: '40',
  43: '43',
}

export const MOBILE_DEVICE_TYPES = {
  EMPLOYEE: 'employee',
  STORE: 'store',
}
