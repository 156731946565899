import React from 'react'

function SitesSurveyCompletion() {
  return (
    <div className="my-lg flex flex-col justify-center text-center py-12 space-y-4">
      <div className="text-md font-bold ">
          Thank you for providing your Site information.
      </div>
      <div className="font-light">
        A QuickTask rep will reach out to you during business hours.
      </div>
    </div>
  )
}

export { SitesSurveyCompletion }
