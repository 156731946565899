import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormikText } from '@src/screens/components/FormikFields/FormikText'
import { FormLayout } from '@therms/atalaya'
import { FormikEmail } from '@src/screens/components/FormikFields/FormikEmail'
import { FormikNumber } from '@src/screens/components/FormikFields/FormikNumber'
import { FormikSelectBrand } from '@src/modules/Brands/shared/FormikSelectBrand'

interface InitialRegistrationInfoFormProps {
  loading: boolean
  onSubmit: (formValues) => void
}

function RegistrationSignupForm({
  loading,
  onSubmit,
}: InitialRegistrationInfoFormProps) {
  const initialValues = {
    organizationName: '',
    numberOfSites: undefined,
    userName: '',
    email: '',
    phone: '',
    brands: [],
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        organizationName: Yup.string().required('Required'),
        userName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string().required('Required'),
        brands: Yup.array().nullable(),
        numberOfSites: Yup.number(),
      })}
    >
      {({ submitForm, values }) => (
        <FormLayout space="xs" horizontal={false}>
          <FormikText label="Organization Name" name="organizationName" />

          <FormikText label="Name" name="userName" placeholder="John Smith" />

          <FormikEmail
            label="Email"
            name="email"
            placeholder="johnsmith@email.com"
          />

          <FormikText label="Phone" name="phone" placeholder="XXX-XXX-XXXX" />

          <FormikSelectBrand
            label="Brand"
            name="brands"
            value={values.brands}
          />

          <FormikNumber
            min={0}
            label="Number of Sites"
            name="numberOfSites"
            placeholder="0"
            subText="The number of c-store sites in your organization"
          />

          <FormLayout.Controls
            disabled={loading}
            onSubmit={submitForm}
            submitLabel="Submit"
          />
        </FormLayout>
      )}
    </Formik>
  )
}

export { RegistrationSignupForm }
