import { useState, useLayoutEffect } from 'react'

export function useMemoDebounced<T = any>(
  value: () => T,
  deps: any[],
  delay: number,
) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  if (!deps) throw new Error('deps array is required')
  if (!delay) throw new Error('delay is required')

  useLayoutEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value())
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, deps)

  return debouncedValue as T
}
