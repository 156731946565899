import React, { useEffect, useState } from 'react'
import { TextWithSuggestions } from '@therms/atalaya'
import { useStateDebounced } from '@src/hooks/useStateDebounced'
import { engineRoomApi } from '@src/services/api'

export interface AddressSuggestionsProps {
  autoComplete?: string | undefined
  label?: string | undefined
  name?: string | undefined
  onChangeValue?: (addressSuggestionDetail: any) => void
  placeholder?: string | undefined
  subText?: React.ReactNode
  value?: string | undefined
}

function AddressSuggestions({
  autoComplete,
  label,
  name,
  onChangeValue,
  placeholder,
  subText,
  value,
}: AddressSuggestionsProps) {
  const [searchText, debouncedSearchText, setSearchText] = useStateDebounced(
    '',
    300,
  )
  const [suggestions, setSuggestions] = useState<
    Awaited<
      ReturnType<typeof engineRoomApi.geo.addressAutocomplete>
    >['data']['results']
  >([])
  const [loading, setLoading] = useState(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    string | undefined
  >(value)

  useEffect(() => {
    if (value) {
      setSelectedSuggestion(value)
    }
  }, [value])

  const fetchSuggestions = async () => {
    setLoading(true)
    try {
      const response = await engineRoomApi.geo.addressAutocomplete({
        text: debouncedSearchText,
      })
      setSuggestions(response.data.results)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (debouncedSearchText) {
      fetchSuggestions()
    } else if (suggestions.length) {
      setSuggestions([])
    }
  }, [debouncedSearchText])

  return (
    <TextWithSuggestions
      label={label}
      name={name}
      autoComplete={autoComplete}
      isLoading={loading}
      suggestions={suggestions?.map((suggestion) => ({
        label: suggestion.formatted_address,
        value: suggestion.formatted_address,
        address: {
          address: suggestion.formatted_address,
          googlePlaceId: suggestion.place_id,
          coords: suggestion.geometry.location,
        },
      }))}
      onChangeValue={(val) => {
        if (selectedSuggestion) setSelectedSuggestion(undefined)
        setSearchText(val)
      }}
      placeholder={placeholder}
      onSuggestionSelected={(selectedValue) => {
        onChangeValue?.(selectedValue.address)
        setSelectedSuggestion(selectedValue.label)
        return selectedValue.label as string
      }}
      search
      subText={subText}
      value={selectedSuggestion || searchText}
    />
  )
}

export { AddressSuggestions }
